import { useCallback, useState } from 'react';
import UserInvitation from '../models/UserInvitation';
import ClientService from '../services/ClientService';
import { useCurrentClient } from '../global-state/Clients';

const useInviteUser = () => {
  const [inviteUserMode, setInviteUserMode] = useState(false);
  const [invitedUser, setInvitedUser] = useState<UserInvitation | null>(null);
  const [inviteUserDisable, setInviteUserDisable] = useState(true);
  const currentClient = useCurrentClient((x) => x.value);

  const inviteUser = useCallback(() => {
    if (currentClient && invitedUser) {
      return ClientService.inviteUser(currentClient?.id, invitedUser);
    }
  }, [invitedUser, currentClient]);

  return { inviteUserMode, setInviteUserMode, inviteUser, invitedUser, setInvitedUser, inviteUserDisable, setInviteUserDisable };
};

export default useInviteUser;
